.userListContain {
    width: 100%;
    height: 700px;

    .listItem {
        display: flex;

        .span {
            display: inline-block;
            width: 100px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            font-weight: 200;
            margin: 50px 0;
        }
        

        .btnEdit {
            padding: 10px;
            overflow: hidden;
            border-radius: 50%;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: #fff;
            border: none;
            outline: none;
    
            &:hover {
                background-color: rgba(85, 85, 85, 0.6);
            }
            &:active {
                background-color: rgba(85, 85, 85, 0.8);
            }
    
            .innerBtn {
                display: flex;
                align-items: center;
    
                .icon {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    background-color: white;
                    color: #fff;
                    margin-left: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .userList {
        background-color: #23252b;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        border-radius: 30px;
        box-shadow: 0px 0px 3px #555555;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-bottom: 20px;
        margin-right: 15px;
    }
}

.loadContain {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
