.login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    overflow: hidden;
    color: #fff;
    background-image: linear-gradient(to right bottom, #213e36, #1e362f, #1b2f28, #172822, #14211c);
    
    .loginContain {
        display: flex;
        width: 80%;
        height: 80%;
        overflow: hidden;

        .loginLeft {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            .mainTitle {
                font-size: 45px;
                font-weight: 700;
            }
            .loginTitleContain {
                display: flex;
                align-items: center;
                font-size: 45px;
                margin-bottom: 1rem;

                .fxLogo {
                    width: 60px;
                    height: 45px;
                    margin: 0 5px;
                }
            }

            .inputContain {
                display: flex;
                align-items: center;
                width: 60%;
                padding: 5px;
                border: 1px solid rgba(19, 204, 104, 0.3);
                border-radius: 10px;
                margin-top: 3rem;

                .loginIcon {
                    margin-right: 10px;
                }

                .loginInput {
                    width: 100%;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    color: #fff;
                }
                .loginInput::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: rgba(255, 255, 255, 0.5);
                    opacity: 1; /* Firefox */
                }
                .loginInput:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: rgba(255, 255, 255, 0.5);
                }
                .loginInput::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: rgba(255, 255, 255, 0.5);
                }
            }
            @media (max-width: 915px) {
                .inputContain {
                    width: calc(100% - 20px);
                }
            }

            .forgetPassContain {
                display: flex;
                justify-content: flex-end;
                width: calc(60% + 10px);
                margin-top: 10px;

                .forgetPass {
                    font-size: 12px;
                    color: #0581db;
                    cursor: pointer;
                }
            }

            .showPass {
                cursor: pointer;
            }

            .noAccount {
                font-size: 15px;
                color: #0581db;
                cursor: pointer;
            }
        }
    }

    .btn {
        width: 130px;
        height: 30px;
        overflow: hidden;
        border-radius: 30px;
        background-color: #13cc68;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        border: none;
        padding: 15px 0;
        margin-top: 60px;
        font-weight: 600;
        margin-bottom: 10px;

        &:hover {
            background-color: rgb(14, 145, 75);
        }

        .innerBtn {
            display: flex;
            align-items: center;

            .icon {
                width: 20px;
                height: 20px;
                border-radius: 10px;
                background-color: white;
                color: #13cc68;
                margin-left: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .btn:active {
        background-color: rgb(7, 82, 42);
    }
}
