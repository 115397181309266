.editNews {
    .card {
        background-color: #23252b;
        width: 270px;
        border-radius: 30px;
        box-shadow: 0px 0px 3px #555555;
        display: flex;
        flex-direction: column;
        padding: 20px;
        overflow: hidden;
        margin-bottom: 20px;
        margin-right: 30px;

        .newsTopic {
            width: 100%;
            text-align: left;
            margin: 10px;
            color: #13cc68;
        }

        .contain {
            display: flex;
            margin-bottom: 2rem;

            .part3 {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                height: 7rem;
                overflow: hidden;
            }

            @media (max-width: 915px) {
                .part {
                    flex: 0.4 0.4 100%;
                }

                .part2 {
                    flex: 0.3 0.3 100%;
                }
            }

            .iconContain {
                display: flex;
                align-items: center;
                color: #fff;
                margin-bottom: 10px;

                @media (max-width: 915px) {
                    .txtTitle {
                        display: inline-block;
                        width: 100px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                    }
                }

                .icon {
                    margin-right: 5px;
                }
            }

            .inputFirst {
                color: #fff;
                font-family: ru;
                border-style: solid;
                border-width: 2px;
                padding: 5px;
                border-radius: 10px;
                border-color: rgba(19, 204, 104, 0.3);
                background-color: rgba(21, 21, 21, 0.8);
            }
            .inputArea {
                color: #fff;
                font-family: ru;
                border-style: solid;
                border-width: 2px;
                padding: 5px;
                border-radius: 10px;
                border-color: rgba(19, 204, 104, 0.3);
                background-color: rgba(21, 21, 21, 0.8);
                resize: none;
                outline: none;
                font-size: 18px;
            }
        }

        .newsBold {
            width: 100%;
            text-align: left;
            margin-top: 10px;
            color: #fff;
            font-weight: 500;
        }

        .news {
            width: 100%;
            text-align: left;
            color: #ababab;
            margin-top: 10px;
        }

        .newsFooter {
            display: flex;
            width: 100%;
            margin-top: 10px;

            .btn {
                width: 130px;
                height: 30px;
                overflow: hidden;
                border-radius: 30px;
                background-color: #13cc68;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #fff;
                border: none;
                padding: 15px 0;
                margin-top: 10px;
                font-weight: 600;
                margin-bottom: 10px;
                margin-right: 10px;

                &:hover {
                    background-color: rgb(14, 145, 75);
                }
                &:active {
                    background-color: rgb(7, 82, 42);
                }

                .innerBtn {
                    display: flex;
                    align-items: center;

                    .icon {
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background-color: white;
                        color: #13cc68;
                        margin-left: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .btnCancel {
                width: 130px;
                height: 30px;
                overflow: hidden;
                border-radius: 30px;
                background-color: #b3b3b3;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: rgb(46, 46, 46);
                border: none;
                padding: 15px 0;
                margin-top: 10px;
                font-weight: 600;
                margin-bottom: 10px;

                &:hover {
                    background-color: rgb(110, 110, 110);
                }
                &:active {
                    background-color: rgb(65, 65, 65);
                }

                .innerBtn {
                    display: flex;
                    align-items: center;

                    .icon {
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background-color: white;
                        color: #13cc68;
                        margin-left: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }

    .inputContain {
        display: flex;
        align-items: center;
        width: calc(100% - 20px);
        padding: 5px;
        border: 1px solid rgba(19, 204, 104, 0.3);
        border-radius: 10px;
        margin-top: 3rem;

        .switchTitle {
            font-size: 15px;
            color: #fff;
            margin-right: 10px;
        }

        .loginIcon {
            margin-right: 10px;
        }

        .loginInput {
            width: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            color: #fff;
        }
        .loginInput::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(255, 255, 255, 0.5);
            opacity: 1; /* Firefox */
        }
        .loginInput:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(255, 255, 255, 0.5);
        }
        .loginInput::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(255, 255, 255, 0.5);
        }
    }
    @media (max-width: 915px) {
        .inputContain {
            width: calc(100% - 20px);
        }
    }

    .inputContain {
        display: flex;
        align-items: center;
        width: calc(100% - 20px);
        padding: 5px;
        border: 1px solid rgba(19, 204, 104, 0.3);
        border-radius: 10px;
        margin-top: 3rem;

        .switchTitle {
            font-size: 15px;
            color: #fff;
            margin-right: 10px;
        }

        .loginIcon {
            margin-right: 10px;
        }

        .loginInput {
            width: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            color: #fff;
        }
        .loginInput::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(255, 255, 255, 0.5);
            opacity: 1; /* Firefox */
        }
        .loginInput:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(255, 255, 255, 0.5);
        }
        .loginInput::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(255, 255, 255, 0.5);
        }
    }
    @media (max-width: 915px) {
        .inputContain {
            width: calc(100% - 20px);
        }
    }
}