 .notifCardContain {
    width: 100%;
    height: 700px;

    .listItem {
        display: flex;

        .span {
            display: flex;
            align-items: center;
            margin: 50px 0;
        }
    }

    .notifCard {
        background-color: #23252b;
        width: 200px;
        border-radius: 30px;
        box-shadow: 0px 0px 3px #555555;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-bottom: 20px;
        margin-right: 15px;
    
        .cardHeader {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 10%;
            position: relative;
            color: #fff;
            font-weight: 500;
            text-transform: uppercase;
    
            .triangle {
                width: 30px;
                height: 20px;
                clip-path: polygon(100% 0, 0 0, 50% 100%);
                position: absolute;
                bottom: -20px;
            }

            .btnEdit {
                padding: 10px;
                overflow: hidden;
                border-radius: 50%;
                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #fff;
                border: none;
                outline: none;
                position: absolute;
                top: 5px;
                right: 5px;
        
                &:hover {
                    background-color: rgba(85, 85, 85, 0.6);
                }
                &:active {
                    background-color: rgba(85, 85, 85, 0.8);
                }
        
                .innerBtn {
                    display: flex;
                    align-items: center;
        
                    .icon {
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background-color: white;
                        color: #fff;
                        margin-left: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .btnDelete {
                padding: 10px;
                overflow: hidden;
                border-radius: 50%;
                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #fff;
                border: none;
                outline: none;
                position: absolute;
                top: 5px;
                left: 5px;
        
                &:hover {
                    background-color: rgba(85, 85, 85, 0.6);
                }
                &:active {
                    background-color: rgba(85, 85, 85, 0.8);
                }
        
                .innerBtn {
                    display: flex;
                    align-items: center;
        
                    .icon {
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background-color: white;
                        color: #fff;
                        margin-left: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    
        .bodyContain {
            width: calc(100% - 20px);
            height: 25rem;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            padding: 10px;
            margin-top: 20px;
    
            .first {
                flex: 0.3;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
    
                .price {
                    color: #fff;
                    font-size: 25px;
                    font-weight: 500;
                }
                .other {
                    color: #fff;
                    font-size: 15px;
                }
    
                .line {
                    width: 50px;
                    height: 2px;
                    background-color: #13cc68;
                    position: absolute;
                    bottom: 8px;
                }
            }
            .second {
                flex: 1;
                display: flex;
                flex-direction: column;
    
                .infoContain {
                    flex: 1;
                    display: flex;
    
                    .infoLeft {
                        flex: 0.4;
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
                        .infoIcon {
                            font-size: 18px;
                            color: #fff;
                        }
                    }
                    .infoMiddle {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        font-size: 11px;
                        color: #fff;
                    }
                    .infoRight {
                        flex: 0.7;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 11px;
                        color: #fff;
    
                        .infoIcon {
                            font-size: 18px;
                        }
                    }
                }
            }
    
            .topicContain {
                display: flex;
                align-items: center;
                color: #13cc68;
                margin-bottom: 10px;
    
                .topic {
                    font-size: 15px;
                    font-weight: 600;
                    color: white;
                    margin-left: 15px;
                }
            }
    
            .body {
                color: white;
                font-size: 15px;
            }
        }
    }
 }

 .loadContain {
     display: flex;
     align-items: center;
     justify-content: center;
     width: 100%;
     height: 100%;
 }