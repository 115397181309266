.tickets {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to right bottom, #26262e, #202329, #1b1f23, #171b1d, #141718);
    height: 100vh;
    overflow: hidden;
    color: #fff;
    position: relative;

    .supportContain {
        display: flex;
        width: calc(100% - 80px);
        transition: transform 0.8s ease-in-out, opacity 1s linear;
        position: absolute;
        padding: 60px 40px 0;
        overflow: hidden;
    }
    .supportContain.left {
        opacity: 0;
        transform: translateX(-100%);
    }
    @media (max-width: 915px) {
        .supportContain {
            width: 100%;
            padding: 40px 0;
        }
    }
}