.notif {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to right bottom, #26262e, #202329, #1b1f23, #171b1d, #141718);
    height: calc(100vh - 90px);
    overflow: hidden;
    overflow-y: auto;
    padding: 10px;

    .floatBtn {
        display: flex;
        justify-content: center; 
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        right: 25px;
        bottom: 10px;
        background-color: #13cc68;
        outline: none;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: #11a154;
        }
        &:active {
            background-color: #0d723c;
        }
    }
}