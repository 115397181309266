.newsListContain {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .newsList {
        background-color: #23252b;
        width: 230px;
        border-radius: 30px;
        box-shadow: 0px 0px 3px #555555;
        display: flex;
        flex-direction: column;
        padding: 20px;
        overflow: hidden;
        margin-bottom: 10px;

        .operation {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-bottom: 10px;

            .btn {
                padding: 10px;
                overflow: hidden;
                border-radius: 50%;
                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #fff;
                border: none;
                outline: none;
        
                &:hover {
                    background-color: rgba(85, 85, 85, 0.6);
                }
                &:active {
                    background-color: rgba(85, 85, 85, 0.8);
                }
        
                .innerBtn {
                    display: flex;
                    align-items: center;
        
                    .icon {
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background-color: white;
                        color: #fff;
                        margin-left: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

        .imgNewsContain {
            width: 100%;
            height: 180px;
            border-radius: 30px;
            overflow: hidden;

            .imgNews {
                width: 100%;
                height: 100%;
            }
        }

        .newsTopic {
            width: 100%;
            text-align: left;
            margin-top: 10px;
            color: #13cc68;
        }

        .newsBold {
            width: 100%;
            text-align: left;
            margin-top: 10px;
            color: #fff;
            font-weight: 500;
        }

        .newss {
            width: 100%;
            text-align: left;
            color: #ababab;
            margin-top: 10px;
        }

        .newsFooter {
            display: flex;
            width: 100%;
            margin-top: 10px;

            .newsLeft {
                width: 100%;
                text-align: left;
                color: #dbdbdb;
            }
            .newsRight {
                width: 100%;
                text-align: right;
                color: #dbdbdb;
            }
        }
    }
}
