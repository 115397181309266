.App {
  display: flex;
  width: 100vw;
  overflow: hidden;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .mainSide {
    flex: 4;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }
  .mainSide.close {
    display: none;
  }
}
.link {
    text-decoration: none;
    color: inherit;
    cursor: auto;
}
